
.navbar-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 0px;
    top: 160px;
    z-index: 2000;

    a {
        margin: 0px;
    }
}

.nav-btn {
    align-items: center;
    border: none;
    border-radius: 2rem;
    color: white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 10px 10px 15px;
    position: absolute;
    right: -125px;
    transition: right .7s, padding .5s;
    width: 170px;

    p {
        margin-left: 20px;
        transition: margin-left .3s ease-in;
    }

    &:hover {
        right: -20px;

        p {
            margin-left: 10px;
        }
    }

    &.info {
        background-color: lightseagreen;
        margin-top: 0px;
    }

    &.micro {
        background-color: dodgerblue;
        margin-top: 4rem;
    }

    &.estaciones {
        background-color: mediumslateblue;
        margin-top: 8rem;
    }

    &.ocean-hub {
        background-color: mediumvioletred;
        margin-top: 12rem;
        right: -145px;
        width: 190px;
        white-space: nowrap;

        &:hover {
            right: -20px;
        }
    }
}