
.dash-btn {
    align-items: center;
    background-color: transparent;
    border: 2px solid transparent;
    border-radius: 30%;
    cursor: pointer;
    display: flex;
    height: min-content;
    justify-content: center; 
    margin: 10px 0px;
    padding: 5px;
    position: relative;
    transition: border .3s ease-in;

    &.active {
        border: 2px solid $nav1;
        border-radius: 30%;
    }
    
    &:hover .tooltiptext {
        visibility: visible;
    }

    .tooltiptext {
        background-color: $nav1;
        border-radius: 10px;
        color: white;
        font-weight: bolder;
        left: 70px;
        padding: 10px;
        position: absolute;
        text-align: center;
        visibility: hidden;
        width: fit-content; 
    }

    @media screen and (min-width: $small) and (max-width: 1700px) {
		margin: 0px 10px;

        .tooltiptext {
            left: auto;
            top: 70px;
        }
	}
}

.panel-tool-btn {
    border: none;
    border-radius: 0px 4px 4px 0px;
    box-shadow: 10px 7px 10px $transparent-black;
    cursor: pointer;
    height: 40px;
    margin: 5px 0px;
    width: 40px;
    padding: 5px 8px;
    position: relative;

    &:focus-visible {
        outline: none;
        box-shadow: none;
    }
    
    &:first-of-type {
        margin-top: 0px;
    }

    &.slider {
        background-color: white;
    }
    
    &.preview {
        background-color: $panel-search-nav-btn;
        color: white;
    }

    .tooltiptext {
        background-color: $nav1;
        border-radius: 10px;
        color: white;
        font-weight: bolder;
        left: 50px;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: 0px;
        width: fit-content; 
        z-index: 200;
    }
}

.panel-nav-btn {
    background-color: white;
    border: 1px solid lightgray;
    color: black;
    font-size: 18px;
    letter-spacing: 1px;
    margin-bottom: -1px;
    padding: 5px 10px;

    &:first-of-type {
        border-radius: 4px 4px 0px 0px;
    }

    &:last-of-type {
        border-radius: 0px 0px 4px 4px;
    }

    &.active {
        background: $panel-nav-btn;
        border: 1px solid $panel-nav-btn;
        color: white;
        pointer-events: none;
    }
}

.search-filter-btn {
    background-color: transparent;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0px 5px;
    padding: 5px 10px;
    transition: background-color .3s ease-in, color .3s ease-in;
    
    &:hover {
        background-color: $hover;
    }

    &.active {
        background-color: $panel-search-nav-btn;
        color: white;
        pointer-events: none;
        
    }
}

.tag-btn{
    
    width: 90%;
    background-color: $hover;
    border: none;
    border-radius: 0.4rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.5px;

    &:hover {
        background-color: $nav2;
        border: none;
        color: white;
    }

    &.active {
        background-color: $nav2;
        color: white;
        pointer-events: none;
        
    }

}
.tag-btn p{
    
    font-size: 1.0rem;
    color: gray;
    object-fit: cover;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    &:hover {
        background-color: $nav2;
        border: none;
        color: white;
    }
}

.location-btn {
    background-color: white;
    border: 1px solid lightgray;
    color: darkgray;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 0px -1px -1px 0px;
    padding: 5px 10px;
    transition: background-color .3s ease, border .3s ease, color .3s ease;
    z-index: 50;

    &:disabled {
        color: lightgray;
        cursor: default;
    }

    &:enabled:not(.active):hover {
        background-color: $hover;
    }

    &.active {
        background-color: $panel-filter-btn;
        border: 1px solid $panel-filter-btn;
        color: white;
        font-weight: bold;
    }

    &.trf {
        border-radius: 4px 0px 0px 4px;
        
    }

    &.trl {
        border-radius: 0px 4px 4px 0px;
    }

    &.bri {
        border-top: none;
        border-radius: 0px 0px 4px 4px;
        z-index: 0;
    }
}

.search-btn {
    border-radius: .375rem;
    color: darkgray;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 5px 10px;
    transition: background-color .3s ease-in, border .3s ease-in, color .3s ease-in;

    &:disabled {
        background-color: white;
        border: 1px solid lightgray;
    }

    &:enabled {
        background-color: $panel-search-btn;
        border: 1px solid $panel-search-btn;
        color: white;
        cursor: pointer;
        font-weight: bold;
    }

    &:enabled:hover {
        background-color: darken($color: $panel-search-btn, $amount: 20);
        border: 1px solid darken($color: $panel-search-btn, $amount: 20);
    }
}

.preview-btn {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    margin: 0px 5px;
    padding: 5px 10px;
    transition: all .3s ease;

    &:last-of-type {
        margin-right: 0px;
    }

    &:disabled {
        background-color: white;
        border: 2px solid lightgray;
        color: darkgray;
        cursor: default;
    }

    &:enabled {
        background-color: $terciary;
        border: 2px solid $terciary;
        color: white;
    }

    &:enabled:hover {
        background-color: lighten($color: $terciary, $amount: 10);
        border: 2px solid lighten($color: $terciary, $amount: 10);
        color: white;
    }
}

.dt-btn {
    align-items: center;
    background-color: white;
    border: 1px solid lightgray;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    justify-content: center;
    letter-spacing: 1px;
    margin: 0px 0px -1px -1px;
    padding: 5px 10px;
    transition: background-color .3s ease-in, color .3s ease-in, border-color .3s ease-in;
    
    &.active {
        background-color: $terciary;
        border-color: $terciary;
        color: white;
    }

    &:last-child {
        border-top-right-radius: 4px;
    }
}

.page-btn {
    border: none;
    background-color: white;
    border-radius: 100%;
    color: black;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    margin: 0px 5px;
    transition: background-color .3s ease-in, color .3s ease-in;

    &.active {
        background-color: lightskyblue;
        color: white;
    }
}

.maptool-btn {
    align-items: center;
    border: none;
    border-radius: 40px;
    bottom: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: center;
    margin: 0px 10px;
    padding: 15px 0px;
    position: absolute;
    transition: height .3s ease-in, background-color .3s ease;
    width: 70px;

    &:hover {
        .tooltiptext {
            visibility: visible;
        }
    }

    &:nth-child(1) {
        margin-left: 0px;
    }

    &:nth-child(2) {
        left: 105px;
    }

    &:nth-child(3) {
        left: 190px;
        margin-right: 0px;
    }

    &.layer {
        background-color: dodgerblue;

        &:hover {
            background-color: darken($color: dodgerblue, $amount: 20);
        }
    }

    &.draw {
        background-color: lightseagreen;

        &:hover {
            background-color: darken($color: lightseagreen, $amount: 20);
        }
    }

    &.measure {
        background-color: slateblue;

        &:hover {
            background-color: darken($color: slateblue, $amount: 10);
        }  
    }

    &.marker {
        background-color: coral;

        &:hover {
            background-color: darken($color: coral, $amount: 20);
        }
    }

    &.expandable {
        cursor: default;
        justify-content: flex-end;
        left: 0px;
        overflow: hidden;
        
        &:hover {
            height: 225px;
        }   
    }

    .tooltiptext {
        background-color: black;
        border-radius: 5px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        left: 0px;
        padding: 5px 10px;
        position: absolute;
        top: -55px;
        visibility: hidden;
        width: 50px;
    }
}

.popup-btn {
    align-items: center;
    background-color: white;
    border: none;
    border-radius: 100%;
    color: white;
    cursor: pointer;
    display: flex;
    height: 50px;
    justify-content: center;
    margin: 0px 5px;
    padding: 10px 15px;
    transition: background-color .3s ease;
    width: 50px;

    &.edit {
        background-color: seagreen;

        &:hover {
            background-color: darken($color: seagreen, $amount: 10);
        }
    }

    &.delete {
        background-color: crimson;

        &:hover {
            background-color: darken($color: crimson, $amount: 10);
        }
    }

    &.color {
        background-color: #FFC300;

        &:hover {
            background-color: darken($color: #FFC300, $amount: 10);
        }
    }
}

.enable-maptools-btn {
    align-items: center;
    background-color: $panel-filter-btn;
    border: none;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    justify-content: center;
    padding: 10px;
    transition: background-color .3s ease;

    &:hover {
        background-color: darken($color: $panel-filter-btn, $amount: 20);
    }
}
