.geoviewer-content {
	display: flex;
	box-sizing: border-box;
	grid-area: component;
	position: relative;
	z-index: 100;
	height: 100%;
	width: 100%;
}

.panel-container {
	display: flex;
	flex-direction: row;
	position: absolute;
	transition: left 1s ease-out;
	top: 0;
	z-index: 300;

	&.micro {
		left: -520px;
	}

	&.est {
		left: -430px;
	}

	&.active {
		left: 0px;
	}
}

.panel {
	background-color: white;
	border-radius: 0px 0px 4px 0px;
	display: flex;
	flex-direction: row;
	padding: 20px 20px 20px 10px;
	transition: box-shadow .5s ease-in;

	&.micro {
		width: 500px;
	}

	&.est {
		width: 400px;
	}

	&.active {
		box-shadow: 10px 7px 10px $transparent-black;
	}
}

.panel-tools-container {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: flex-start;
	margin-top: 20px;
	overflow: hidden;
}

.panel-nav {
	align-items: center;
	align-self: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: 15px;
} 

.panel-loading-options {
    align-self: center;
    font-size: 20px;
    letter-spacing: 1px;
}

.panel-content {
	display: flex;
	flex: 1;
} 

.menu-container {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	padding: 5px;

	h3 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
		text-align: center;
		width: 100%;
	}

	*::-webkit-scrollbar-thumb {
        background-color: darkgray;
        border-radius: 2rem;
    }
} 

.menu-filter {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}

.menu-content {
	align-items: center;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: flex-start;
	margin-top: 20px;
	width: 100%;
}

.menu-col {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	justify-content: center;
	margin-bottom: 20px;
	width: 100%;

	p {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 10px;
	}	
}

.menu-row {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-weight: normal;
	justify-content: center;
	margin-bottom: 20px;
	width: 100%;

	p {
		color: darkgray;
		margin: 0px;
	}

	&:last-child {
		margin: 0px;
	}

	.row-text {
		align-items: center;
		display: flex;
		font-size: 16px;
		font-weight: bold;
		justify-content: flex-start;
		width: 30%;
		word-wrap: break-word;

		&.right {
			justify-content: flex-end;
		}
	}
	
	.row-filter {
		align-items: center;
		display: block;
		font-size: 16px;
		justify-content: flex-start;
		width: 60%;

		.check-item {
			align-items: center;
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin: 0px 10px;

			input {
				margin-right: 10px;
			}

			p {	
				font-weight: normal;
				margin: 0px;
			}
		}
	}
}

.menu-date-container {
	display: flex;
	flex-wrap: wrap;
	height: 38px;
	outline: none;
  	text-align: center;
	width: 50%;
}

.menu-date-separator {
	background-color: darkgray;
	border: none;
	height: 1px;
	margin: 0px 5px;
	width: 20px;
}

.menu-date {
	align-items: center;
	border: none;
	border-radius: 4px;
	box-sizing: border-box;
	display: flex;
	font-size: 16px;
	height: 100%;
	justify-content: center;
	letter-spacing: .5px;
	outline: none;
	text-align: center;
	width: 100%;
}

.alert {
	color: $panel-search-nav-btn;
	font-weight: bold;
	text-align: center;
}

.loading-container {
	align-items: center;
	background-color: $transparent-black;
	color: white;
	display: flex;
	font-size: 20px;
    font-weight: bold;
	grid-area: component;
	height: 100%;
	justify-content: center;
	left: 0;
	letter-spacing: 1px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3000;
}

.input-doi{
	align-items: center;
    border: 0;
    border-radius: 40px;
    color: rgba(0, 0, 0, 0.911);
    display: flex;
    font-weight: 500;
    height: 35px;
    margin: 0 0 0 16px;
    padding: 0 8px;
    width: 100%;
    margin: 0;
    border: 0.1rem solid rgba(95, 95, 95, 0.733);
    border-radius: 0.375rem;
}