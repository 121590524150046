.preview-container {
    background-image: url('../../assets/img/preview_bkgd.svg');
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
	left: 0;
    overflow: auto;
	padding: 20px;
	position: absolute;
	transition: display 1s ease;
	transition-delay: 1s;
	top: 0;
	width: 100%;
	z-index: 300;
	
    th, td {
        background-color: white;
        border: 1px solid lightgray;
    }

	&::-webkit-scrollbar-thumb {
		background-color: $primary;
		border-radius: 2rem;
	}

	.not-active {
		display: none;
	}
}	

.preview-header {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.preview-header-row {
	align-items: flex-start;
	display: flex;
	flex: 1;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;

	h1 {
		font-size: 26px;
		font-weight: bold;
	}
}

.preview-btn-container {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.preview-row {
	align-items: flex-start;
	display: flex;
	flex-direction: row;
    flex-wrap: wrap;
	margin-top: 20px;

	p {
		font-size: 18px;
        margin: 0px 50px 15px 0px;
        white-space: nowrap;
	}
}

.checktable-container {
	box-sizing: border-box;
	display: block;
    max-width: 100%;
    overflow-y: hidden;

    &::-webkit-scrollbar-thumb {
		background-color: $terciary;
		border-radius: 2rem;
		width: .1em !important;
    }
}

.preview-dt-row {
	align-items: flex-start;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	margin-top: 20px;
	max-width: 100%;
}

.datatable-container {
	box-sizing: border-box;
	display: flex;
	flex: 1 1 auto;
	overflow: auto;
	max-height: 100%;
	width: 100%;

	&::-webkit-scrollbar-thumb {
		background-color: $terciary;
		border-radius: 2rem;
		width: .1em !important;
    }
}

.dt-tab-container {
	align-items: center;
    border-radius: 4px 4px 0px 0px;
	display: flex;
    flex-wrap: wrap;
    margin-bottom: -1px;
    max-width: 100%;
    overflow: hidden;
    width: fit-content;
}

.message-container {
	align-items: center;
	background-color: $transparent-black-dark;
	display: flex;
	height: 100%;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 300;
}

.confirm {
	align-items: center;
	background-color: white;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	justify-content: center;
	max-width: 700px;
	padding: 30px;

    @media screen and (min-width: 1001px) and (max-width: 1300px) {
        font-size: 16px;
    }

    @media screen and (min-width: 500px) and (max-width: 1000px) {
        width: 80%;
        font-size: 14px;
    }
}

.confirm-row {
	align-items: center;
	display: flex;
	flex-direction: row;
	height: min-content;
	justify-content: center;
	margin-top: 20px;
    text-align: center;

	&:first-of-type {
		margin-top: 0px;
	}
   
	input {
		margin: 0px 10px;
	}	
}

.confirm-row-info {
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: min-content;
	justify-content: center;
	margin-top: 10px;

	p {
		font-size: 18px;
		margin-bottom: 10px;
		text-align: justify;

		&:last-of-type {
			margin-bottom: 0px;
		}

        @media screen and (min-width: 1001px) and (max-width: 1400px) {
            font-size: 16px;
        }

        @media screen and (min-width: 500px) and (max-width: 1000px) {
            font-size: 14px;
        }
	}	
}
textarea{
    
    border: none;
    outline: none;
    resize: none;
    
}
.textarea-style{
	margin-top: 5px;
	width: auto;
	height: auto;
	border: 1px solid #1E79AD;
	border-radius: 2px;
	font-size: 16px;
}
.textarea-style:hover{
	-moz-box-shadow: 0px 0px 10px 1px  #EBF6FF;
	-webkit-box-shadow: 0px 0px 10px 1px  #EBF6FF;
	box-shadow: 0px 0px 10px 1px  #EBF6FF;
	border: 1px solid #229CFF;

}
.textarea-style:focus{
	-moz-box-shadow: 0px 0px 5px 1px #EBF6FF;
	-webkit-box-shadow: 0px 0px 5px 1px  #EBF6FF;
	box-shadow: 0px 0px 5px 1px  #EBF6FF;
	border: 1px solid #229CFF;
}
  
.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;
    
    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;
        
        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }
        
        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate-circle 4.25s ease-in;
        }
        
        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }
        
        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;
            
            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon-line-tip 0.75s;
            }
            
            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon-line-long 0.75s;
            }
        }
        
        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }
        
        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }
    5% {
        transform: rotate(-45deg);
    }
    12% {
        transform: rotate(-405deg);
    }
    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }
    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }
    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }
    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }
    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }
    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}