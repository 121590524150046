* {
    font-family: 'Nunito', sans-serif !important;
    margin: 0px;
    user-select: none;

    table {	
		border-collapse: collapse;
	}

	tr {
		border-bottom: 1px solid lightgray;
		margin: 5px 0px;
	}

	th, td {
        font-size: 14px;
		padding: 10px 10px;
		text-align: center;
        white-space: nowrap;
	}

    &::-webkit-scrollbar {
        height: .8em;
        width: .8em;
    }
    
    &::-webkit-scrollbar-track {
        background: white;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
}

html, body {
    height: 100vh;
    width: 100vw; 
}

main {
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}

.no-events {
    pointer-events: none;
}

.fw {
    width: 100%;
}

.wrp {
    flex-wrap: wrap;
}

.main-container {
	display: grid;
	gap: 0px 0px;
	grid-auto-flow: column;
	grid-template-areas: 
        "header"
		"container";
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 20fr;
	height: 100vh;
	max-width: 100vw;
    overflow-x: hidden;
    position: relative;
}

.component-container {
    align-items: center;
	display: flex;
	flex-direction: column;
    grid-area: container;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.map {
    height: 100%;
    width: 100%;
    z-index: 0;
}

.maptools-container {
    background-color: rgba(51, 51, 51, 0.9);
    border: 1px solid black;
    border-radius: 1.5rem;
    bottom: 0;
    display: flex;
    height: 50px;
    left: 0; 
    margin: 0px auto 30px auto;
    position: absolute;
    right: 0;
    width: min-content;
    z-index: 400;  
}

.maptools-side {
    align-items: center;
    background-color: transparent;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: center;
    overflow: hidden;
    padding: 10px 20px;

    &.l {
        border-right: 2px solid black;
        margin-right: -1px;
    }

    &.r {
        border-left: 2px solid black;
        margin-left: -1px;
    }
}

.maptools-bar-wrapper {
    height: auto;
    overflow-x: clip;
    position: relative;
}

.maptools-bar { 
    display: flex;
    height: 100%;
    overflow-y: visible;
    padding: 0px 30px;
    position: relative;
    transition: padding .5s ease-in, width .5s ease-in;
    width: 240px;

    img {
        height: 50px;
        width: 50px;
    }
}

.expandable-container {
    align-items: center;
    bottom: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    left: 0px;
    position: relative;
    width: 70px;
    
    &:hover {
        .tooltiptext {
            opacity: 1;
            visibility: visible;
        }
    }

    .tooltiptext {
        background-color: black;
        border-radius: 5px;
        color: white;
        display: flex;
        font-size: 12px;
        font-weight: bold;
        justify-content: center;
        left: 0px;
        opacity: 0;
        padding: 5px 10px;
        position: absolute;
        top: -230px;
        visibility: hidden;
        width: 50px;
        transition: visibility ease-in;
        transition-delay: .4s;
    }
}

.secondary-icon {
    align-items: center;
    background-color: white;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    height: 30px;
    justify-content: center;
    margin: 10px 0px;
    padding: 5px;
    position: relative;
    transition: color .3s ease;
    width: 30px;

    &:first-child {
        margin-top: 0px;
    }

    &:last-child {
        margin-bottom: 15px;
    }

    &:hover {
        .secondary-icon-tooltip {
            visibility: visible;
        }
    }

    &.draw {
        color: lightskyblue;
        
        &:hover {
            color: darken($color: lightskyblue, $amount: 20)
        }
    }

    &.delete {
        color: lightcoral;
        
        &:hover {
            color: darken($color: lightcoral, $amount: 20)
        }
    }
}

.popup-btn-container {
    align-items: center;
    display: flex;
    justify-content: center;
}

.color-selector {
    opacity: 0;
    position: absolute;
}

.marker-popup {
    align-items: flex-start;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    justify-content: center;
    margin-bottom: 35px;
    padding: 10px;

    p {
        margin: 0px;
    }
}

.img-maptool {
    height: 15px;
    width: 15px;
}

.loading-layer {
    align-items: center;
    background-color: $transparent-black;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    height: 100%;
    position: absolute;
    width: 100%;
    z-index: 200;
}

.loader {
    align-items: center;
	background-color: $primary;
    color: white;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    top: 0; left: 0;
    width: 100%; 

    h1 {
        font-size: 24px;
    }
}

.loader-element {
    background: white;
    border-radius: 2rem;
	height: 10px;
    margin-top: 5px;
	width: 30%;
}

.loader-element:before {
    background-color: $nav2;
    border-radius: 2rem;
	content: '';
	display: block;
	height: 10px;
	width: 0;
    animation: getWidth .3s ease-in;
}

@keyframes getWidth {
	100% { width: 100%; }
}

.downloading {
	border-right: .15em solid white;
	color: white;
	display: block;
	font-size: 20px;
    font-weight: bold;
	letter-spacing: 1px;
	margin: 0 auto;
	max-width: fit-content;
	overflow: hidden;
	white-space: nowrap;
	
	animation: 
		typing 8s steps(99, end) infinite,
		blink-caret .75s step-end infinite;
}

@keyframes typing {
	from { width: 0 }
	to { width: 100% }
}

@keyframes blink-caret {
	from, to { border-color: transparent }
	50% { border-color: white; }
}