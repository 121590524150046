// Colors
$primary: #003566;
$secondary: #001D3D;
$terciary: #004170;

$nav1: #32B4FF;
$nav2: #199AE5;
$nav3: #1E79AD;

$hover: #EDEDED;
$focus: #A3DDFF;

$panel-nav-btn: lighten($color: $primary, $amount: 10);
$panel-search-nav-btn: lighten($color: $primary, $amount: 20);
$panel-filter-btn: lighten($color: $primary, $amount: 30);
$panel-search-btn: lighten($color: $primary, $amount: 40);

$panel-est-filter-btn: lighten($color: $primary, $amount: 30);

$layer-download: #3EC4FA;

$preview: #e7e8ec;

$transparent-black: rgba(0, 0, 0, 0.5);
$transparent-black-dark: rgba(0, 0, 0, 0.8);

$chart-height: 300px;
$chart-width: 400px;

$small: 768px;
$medium: 1400px;