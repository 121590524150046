.buscador-content {
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	position: relative;
    height: 100%;
	overflow: hidden;
    width: 100%;
	z-index: 100;
}

.buscador-result {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	height: 100%;
	padding: 20px 50px 20px 30px;
	width: 100%;
	
	p {
		font-size: 18px;
	}

	* {
		&::-webkit-scrollbar {
			width: 20px;
		}
	
		&::-webkit-scrollbar-thumb {
			background-color: lightskyblue;
			border-radius: 2rem;
		}
	}
}

.result-info-filter {
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
}

.filter-input {
	border: 1px solid lightgray;
	border-radius: 1rem;
	font-size: 16px;
	margin-right: 60px;
	padding: 5px 15px;
	width: 400px;

	&:focus-visible {
		border: 2px solid lightblue;
		outline: none;
	}
}

.documents-container {
	display: block;
	height: 100%;
	margin-top: 20px;
	overflow: auto;
	padding-right: 30px;
}

.document {
	background-color: white;
	border: 1px solid lightblue;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	height: fit-content;
	margin: 20px 0px;
	overflow: auto;

	&:first-of-type {
		margin-top: 0px;
	}

	&:last-of-type {
		margin-bottom: 0px;
	}

	.icon {
		align-items: flex-start;
		display: flex;
		font-weight: lighter;
		justify-content: center;
		padding: 25px 20px 10px 20px;

		img {
			@media screen and (min-width: 0px) and (max-width: px) {
				display: none;
			}
		}
	}

	.atts {
		display: flex;
		flex-direction: column;
		padding: 20px 0px;
		text-align: justify;
		text-justify: inter-word;
		padding-right: 30px;

		a {
			color: $nav1;
			cursor: pointer;
			font-weight: bold;
			margin-bottom: 5px;

			&:hover {
				color: darken($color: $nav1, $amount: 20);
			}
		}

		p {
			font-size: 14px;
			margin: 5px 5px 5px 0px;
		}

		
	}
	
	.buttons {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0px 40px;
	}
}

.loading-container-docs {
	align-items: center;
	background-color: $transparent-black-dark;
	color: white;
	display: flex;
	font-size: 20px;
    font-weight: bold;
	grid-area: component;
	height: 100%;
	justify-content: center;
	left: 0;
	letter-spacing: 1px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 3000;
}

.documents-pagination {
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin-top: 15px;
}

.modal-bg {
	background-color: rgba(74, 84, 121, 0.466);
	width: 100vw;
	height: 100vh;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
  }
  
  .modal-centered {
	position: fixed;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  
  .modal {
	padding-top: 12px;
	background: #f5f6f7;
    border-radius: 6px;
    box-shadow: inset 1px 1px 0 0 hsla(0,0%,100%,0.5),0 3px 8px 0 #555a64;
    flex-direction: column;
	margin: 60px auto auto;
    width: 600px;
    position: relative;
  }
  
  .modal-header {
	height: auto;
	background: #f5f6f7;
	overflow: hidden;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
  }
  
  .modal-title {
	margin: 0;
	padding: 10px;
	color: #2c3e50;
	font-weight: 500;
	font-size: 18px;
	text-align: center;
  }
  
  .modal-content {
	max-height: calc(600px - 56px - 12px - 44px);
    min-height: 12px;
    overflow-y: auto;
    overflow-y: overlay;
    padding: 0 12px;
  }
  .modal-starScreen{
	font-size: .9em;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
    width: 80%;
  }
  
  .modal-actions {
	position: absolute;
	bottom: 2px;
	margin-bottom: 10px;
	width: 100%;
  }
  
  .actions-container {
	display: flex;
	justify-content: space-around;
	align-items: center;
  }
  
  .close-btn {
	cursor: pointer;
	font-weight: 500;
	padding: 4px 8px;
	border-radius: 8px;
	border: none;
	font-size: 18px;
	color: #2c3e50;
	background: white;
	transition: all 0.25s ease;
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
	position: absolute;
	right: 0;
	top: 0;
	align-self: flex-end;
	margin-top: -7px;
	margin-right: -7px;
}

.close-btn:hover {
	box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
	transform: translate(-4px, 4px);
}


.cancel-btn {
	cursor: pointer;
	font-weight: 500;
	padding: 8px 28px;
	border-radius: 12px;
	font-size: 0.8rem;
	border: none;
	color: #2c3e50;
	background: $hover;
	transition: all 0.25s ease;
	margin-right: 15px;
	&:hover{
		box-shadow: none;
		transform: none;
		color: white;
		background: rgba(110, 110, 110, 0.377);
	}
}

.modal-searchBar{
	display: flex;
    padding: 12px 12px 0;

}

.content-tags{
	margin: 0 auto;
	display: grid; 
	padding: 0;
	grid-template-columns: 1fr 1fr 1fr  ; 
	grid-template-rows: 1fr 1fr; 
	gap: 0px; 

}
.tags-list{
	list-style: none;

}

.modal-footer{
	margin: 30px auto;
	align-items: center;
    background: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -1px 0 0 #e0e3e8,0 -3px 6px 0 rgba(69,98,155,0.12);
    display: flex;
    flex-direction: row-reverse;
    flex-shrink: 0;
    height: 45px;
    justify-content: space-between;
    position: relative;
    user-select: none;
    width: 100%;
    z-index: 300;
	margin-bottom: 0;
}