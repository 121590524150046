
.dash-container {
	align-items: center;
	background: url('../../assets/img/info_bkgd.svg');
	background-repeat: repeat-y;
	background-size: cover;
	display: flex;
	grid-area: component;
	height: 100%;
	justify-content: center;
	position: relative;
	width: 100%;
}

.dash-content {
	align-items: center;
	box-sizing: border-box;
	display: grid;
	gap: 0px;
	grid-template-areas: "btn-controler dash-container map-container";
	grid-template-columns: 1fr 10fr 10fr;
	grid-template-rows: 1fr;
	height: 100%;
	left: 0px;
	padding: 20px;	
	position: absolute;
	top: 0px;
	width: 100%;
	z-index: 100;

	&::-webkit-scrollbar {
		width: 20px;

		@media screen and (min-width: 320px) and (max-width: 560px) {
			display: none;
		}
	}

	&::-webkit-scrollbar-thumb {
		background-color: lightskyblue;
        border-radius: 2rem;
	}
	
	&::-webkit-scrollbar {
		width: 20px;
	}

	@media screen and (min-width: 320px) and (max-width: 1700px) {
		grid-template-areas: 
		"btn-controler"
		"dash-container"
		"map-container";
		grid-template-columns: 1fr;
		grid-template-rows: 1fr 10fr 10fr;
		overflow-x: hidden;
	}
	
	@media screen and (min-width: 1440px) and (max-width: 1700px) {
		grid-template-areas: 
			"dash-container map-container"
			"dash-container map-container";
		grid-template-columns: 1.2fr 1fr;
		grid-template-rows: 1fr;
		overflow-x: hidden;
	}

	@media screen and (min-width: 320px) and (max-width: 1439px) {
		direction: rtl;
	}
}

.dash-btn-controller {
	align-items: center;
	align-self: center;
	direction: ltr;
	display: flex;
	flex-direction: column;
	grid-area: btn-controler;
	height: max-content;
	justify-content: center;
	padding: 0px 5px;
	z-index: 200;

	@media screen and (min-width: 1440px) and (max-width: 1700px) { 
		align-self: flex-start;
		grid-area: dash-container;
        margin-top: 30px;
    }

	@media screen and (min-width: 320px) and (max-width: 1700px) {
		flex-direction: row;
	}
}

.dash_error {
	align-items: center;
	align-self: center;
	color: white;
	display: flex;
	font-size: 30px;
	font-weight: bold;
	justify-content: center;
	position: fixed;
	width: 100%;
}

.dash-charts-container {
	align-self: center;
	direction: ltr;
	display: grid;
	grid-area: dash-container;
	gap: 0px;
	grid-template-columns: repeat(2, minmax(200px, 500px));
	height: fit-content;
	justify-content: center;

	@media screen and (min-width: 1440px) and (max-width: 1700px) {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		grid-area: dash-container;
		justify-content: center;
		padding-top: 50px;
	}

	@media screen and (min-width: 1366px) and (max-width: 1439px) {
		display: grid;
		grid-template-columns: repeat(2, minmax(400px, 500px));
	}

	@media screen and (min-width: 320px) and (max-width: 1419px) {
		display: flex;
		flex-wrap: wrap;
		padding: 0px 30px 0px 30px;
	}
}

.dash-chart-area {
	align-items: center;
	background-color: white;
	border-radius: 1rem;
	color: white;
	display: flex;
	height: $chart-height;
	justify-content: center;
	margin: 20px;
	width: $chart-width;

	@media screen and (min-width: $medium) and (max-width: 1700px) { 
        height: 250px !important;
	    width: 325px !important;
    }

	@media screen and (min-width: 1366px) and (max-width: 1439px) {
		height: 300px !important;
	    width: 400px !important;
	}

	@media screen and (min-width: 1050px) and (max-width: 1365px) {
		height: 300px !important;
	    width: 400px !important;
	}

	@media screen and (min-width: 900px) and (max-width: 1049px) {
		height: 250px !important;
	    width: 350px !important;
	}

	@media screen and (min-width: $small) and (max-width: 899px) {
		height: 220px !important;
	    width: 280px !important;
	}
}

.dash-map-container {
	align-items: center;
	direction: ltr;
	display: flex;
	grid-area: map-container;
	justify-content: center;
	max-height: 800px;
	padding: 30px;

	@media screen and (min-width: 1701px) {
		height: 800px;
	}

	@media screen and (min-width: $medium) and (max-width: 1700px) {
		max-height: 700px;
	}

	@media screen and (min-width: 320px) and (max-width: 1700px) {
		height: calc( 100vw / 1.5 );
		
	}
}

.dash-map-area {
	align-items: center;
	border: 1px solid $secondary;
	border-radius: 1rem;
	display: flex;
	grid-area: map;
	height: 100%;
	justify-content: center;
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 100;
}

.dash-map-loading {
	align-items: center;
	background-color: $transparent-black;
	display: flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	width: 100%;
	z-index: 1100;
}

.dash-chart-text {
	align-items: center;
	color: black;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
	text-align: center;

	.text-title {
		font-size: 24px;
		font-weight: bold;
		margin: 15px 0px;
	}

	img {
		margin: 0px 10px;
		height: 120px;
		vertical-align: middle;
	}

	.text-extra {
		font-size: 18px;
		font-weight: normal;
		margin: 15px 0px;

		img {
			height: 30px;
		}
	}

	@media screen and (min-width: 1440px) and (max-width: 1700px) {
        
		.text-title {
			font-size: 20px;
		}	

		img {
			height: 70px;
		}
	
		.text-extra {
			font-size: 14px;
			
			img {
				height: 26px;
			}
		}
    }

	@media screen and (min-width: 1050px) and (max-width: 1439px) {
        
		.text-title {
			font-size: 24px;
		}	

		img {
			height: 90px;
		}
	
		.text-extra {
			font-size: 18px;
			
			img {
				height: 26px;
			}
		}
    }

	@media screen and (min-width: 900px) and (max-width: 1049px) {
		
		.text-title {
			font-size: 22px;
		}	

		img {
			height: 70px;
		}
	
		.text-extra {
			font-size: 16px;
			
			img {
				height: 24px;
			}
		}
	}

	@media screen and (min-width: $small) and (max-width: 899px) {
		
		.text-title {
			font-size: 18px;
		}	

		img {
			height: 70px;
		}
	
		.text-extra {
			font-size: 14px;
			
			img {
				height: 20px;
			}
		}
	}
}

.map-msg-container {
	align-items: center;
	display: flex;
	justify-content: flex-start;
	left: 15px;
	max-width: 500px;
	position: absolute;
	overflow: hidden;
	top: 15px;
	z-index: 50;
}

.map-msg {
	background-color: white;
	border: 1px solid lightgray;
	border-radius: 4px;
	font-size: 18px;
	height: fit-content;
	letter-spacing: 1px;
	padding: 5px 10px;
	width: fit-content;
}

.map-layer-controller-container {
	align-items: center;
	background-color: white;
	border: 2px solid lightgray;
	border-radius: 4px;
	display: flex;
	height: fit-content;
	justify-content: center;
	max-height: 350px;
	max-width: 500px;
	padding: 5px;
	position: absolute;
	right: 15px;
	top: 15px;
	width: fit-content;
	z-index: 100;

	&:hover {
		.map-layer-controller-img {
			display: none;
		};

		.map-layer-controller-checker {
			display: block;
		}
	}

	*::-webkit-scrollbar-thumb {
		background-color: lightskyblue;
        border-radius: 2rem;
	}
}

.map-layer-controller-checker {
	align-items: center;
	display: none;
	flex-direction: column;
	justify-content: center;
	max-height: 340px;
	max-width: 490px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 5px;
	transition: display .3s ease;
	word-wrap: break-word;
}

.map-layer-controller-row {
	align-items: center;
	display: flex;
	flex-direction: row;
	font-size: 11px;
	justify-content: flex-start;	
	margin: 2px;
	width: 100%;

	input {
		margin-right: 10px;
	}
}










