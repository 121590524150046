
.header-container {
    align-items: center;
    background-color: $secondary;
    border-bottom: 0px solid black;
    color: white;
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-area: header;
    justify-content: space-between;
    padding: 10px 20px;

    @media screen and (min-width: 320px) and (max-width: 1040px) {
        justify-content: center;
		flex-direction: column;
	}
}

.brand-container {
    display: flex;
    flex-direction: row;
}
.brand2-container {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    height: 40px;
    max-width: 800px;
    overflow: hidden;
}
.brand-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 10px;

    @media screen and (min-width: 320px) and (max-width: 1040px) {
		display: none;
	}
}
.brand2-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 20px;
    margin-left: 20px;

    @media screen and (min-width: 320px) and (max-width: 1040px) {
		display: none;
	}
}

.brand-info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 30px;

    @media screen and (min-width: 320px) and (max-width: 1040px) {
		justify-content: center;
        margin-right: 0px;
        margin-bottom: 10px;

        h2, h3 {
            text-align: center;
            width: 100%;
        }
	}
}
.brand2-info {
    align-items: center;
    border: 1px solid black;
    border-radius: 0.375rem;
    display: flex;
    flex: 1 1 auto;
    justify-content: flex-end;
    height: 40px;
    max-width: 800px;
    overflow: hidden;
 

    @media screen and (min-width: 320px) and (max-width: 1040px) {
		justify-content: center;
        margin-right: 0px;
        margin-bottom: 10px;

        h2, h3 {
            text-align: center;
            width: 100%;
        }
	}
}
.invemar-logo {
    height: 55px;
    width: 55px;
}
.language-container{
    align-items: center;
    display: flex;
    justify-content: center;
    margin-right: 0px;

    cursor:pointer;
}
.language-container img{
    width:30px;
}
.bd-search{
    align-items: center;
    flex:1 1 auto;
    justify-content: flex-end;
    position: absolute;
    width: 560px;
    right: 8%;
}
.btn-search{
    align-items: center;
    background: rgba(97, 96, 96, 0.226);
    border: 0;
    border-radius: 40px;
    color: rgba(255, 255, 255, 0.911);
    cursor: pointer;
    display: flex;
    font-weight: 500;
    height: 45px;
    justify-content: space-between;
    margin: 0 0 0 16px;
    padding: 0 8px;
    user-select: none;
    width: 100%;
    margin: 0;
    border: 0.18rem solid rgba(255, 255, 255, 0.733);
    border-radius: 0.375rem;

    &:hover{
        box-shadow:0.18rem solid rgba(255, 255, 255, 0.733); 
    }
}
.btn-search-ctn{
    align-items: center;
    display: flex;
}
.btn-placeholder{
    font-size: 1rem;
    padding: 0 12px 0 6px;
}

