
.searchbar-container {

    // max-width: 400px;
    align-items: center;
    // margin: 2rem auto;
    // flex-direction: column;
    position: relative;


    align-items: center;
    background: rgba(97, 96, 96, 0.226);
    border: 0;
    border-radius: 40px;
    color: rgba(255, 255, 255, 0.911);
    display: flex;
    font-weight: 500;
    height: 45px;
    justify-content: space-between;
    margin: 0 0 0 16px;
    padding: 0 8px;
    user-select: none;
    width: 100%;
    margin: 0;
    border: 0.18rem solid rgba(255, 255, 255, 0.733);
    border-radius: 0.375rem;

    @media screen and (min-width: 320px) and (max-width: 1040px) {
        max-width: 650px;
        width: 90%;
	}
}

.searchbar-container.active {
    border: 2px solid $focus;
}

.searchbar {
    display: flex;
    border-left:none;
    border-bottom: none;
    border-right: none;
    border-top: none;
    background-color:transparent; 
    font-size: 20px;
    height: 40px;
    outline: none;
    padding: 0px 10px;
    transition: color .3s;
    width: 100%;
    color: white;


    @media screen and (min-width: 320px) and (max-width: 1080px) {
        max-width: 1000px;
	}

    &:focus-visible {
        border-bottom: 0px;
        border-top: 0px;
    }

    &.off {
        color: white 
    }

}

.searchbar-options {
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    outline: none;
    padding: 5px;

    option {
        background-color: white;
        color: black;
    }
}

.searchbar-btn {
    align-items: center;
    background-color:transparent;
    border: none;
    color: $primary;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 5px 10px;

    &:disabled {
        color: lightgray
    }
}
.container{
    width: 100%;
    display: flex;
    align-items: center;
    margin: 2rem auto;
    flex-direction: column;
    position: relative;
}

.records{
    border: 1px solid;
    box-shadow: 5px 10px 18px #888888;
    border-radius: 4px;
    border-bottom: 2px solid rgb(255, 255, 255);
    border-left: 2px solid rgb(255, 255, 255);
    border-right: 2px solid rgb(255, 255, 255);
    width: 103%;
    position: absolute;
    margin-top: 60px;
    background-color: white;
    z-index: 1000;
    padding-bottom: 20px;
    
}
.deactive{
    display: none;
}
.item{
    color: black;
}
.header-records{
    font-size: .9em;
    margin: 0 auto;
    padding: 15px 0;
    text-align: center;
    width: 90%;
    color: black;
}
.list-suggestions{
    flex-direction: row;
    list-style: none;
    padding: 8px 0;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
    background-color: none;
    &:hover{
        background-color: $hover;;
    }
}
.list-container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    width: 90%;
    
}
.list-item{
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 0.1fr 1fr 0.1fr;
}
.list-item p{
    font-size: 1.0rem;
    color: gray;
    object-fit: cover;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}