
.layers-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;
    width: 100%;

    h3 {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 10px;
		text-align: center;
		width: 100%;
	}
}

.layer-item {
    align-items: center;
    border-bottom: 1px solid lightblue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 10px 0px;
    width: 100%;
}

.layer-item-icon-area {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 15%;
}

.layer-item-icon {
    color: darkgray;

    &:hover {
        color: darken($color: darkgray, $amount: 30);
    }

    &.active {
        color: black;
    }
}

.layer-item-download-icon {
    color: $layer-download;
    transition: color .3s ease-in;

    &:hover {
        color: darken($color: $layer-download, $amount: 40);
    }
}

.layer-item-text-area {
    display: flex;
    text-align: left;
    text-transform: uppercase;
    width: 70%;
}

