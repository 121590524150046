
.highcharts-container {
	border-radius: 1rem;
	height: $chart-height !important;
	width: $chart-width !important;

    @media screen and (min-width: $medium) and (max-width: 1700px) { 
        height: 250px !important;
	    width: 325px !important;
    }

    @media screen and (min-width: 1050px) and (max-width: 1439px) { 
        height: 300px !important;
	    width: 400px !important;
    }

    @media screen and (min-width: 900px) and (max-width: 1049px) {
		height: 250px !important;
	    width: 350px !important;
	}

    @media screen and (min-width: $small) and (max-width: 899px) {
		height: 220px !important;
	    width: 280px !important;
	}
}

.highcharts-root {
	display: flex !important;
	height: 100% !important;
	max-height: $chart-height !important;
	width: 100% !important;
	font-size: 20px;

    @media screen and (min-width: $medium) and (max-width: 1700px) { 
        height: 250px !important;
    }

    @media screen and (min-width: 1050px) and (max-width: 1439px) { 
        height: 300px !important;
    }

    @media screen and (min-width: 900px) and (max-width: 1049px) {
		height: 250px !important;
    }

    @media screen and (min-width: $small) and (max-width: 899px) {
		height: 220px !important;
	}
}

.highcharts-title {
    font-size: 24px !important;
    font-weight: bold;
}

.highcharts-subtitle {
    font-size: 20px !important;
}

.highcharts-data-labels {

    text {
        font-size: 16px !important;
    }

    tspan {
        font-size: 16px !important;
    }   
}

.highcharts-axis, .highcharts-legend-item {

    text {
        font-size: 20px !important;
    }

    tspan {
        font-size: 20px !important;
    } 
}

.highcharts-credits {
    font-size: 18px !important;  
}

.image-gallery, .image-gallery-content, .image-gallery-slide-wrapper, .image-gallery-swipe, .image-gallery-slides, .image-gallery-slide {
    align-items: center;
    background-color: black;
    border-radius: 1rem;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}

.image-gallery-slide .image-gallery-image {
    cursor: pointer;
    object-fit: fill !important;
}

.image-gallery-svg {
    height: auto !important;
    width: 30px !important;
}

.css-14el2xx-placeholder {
    color: darkgray !important;
}

.css-g1d714-ValueContainer {
    padding: 4px 0px 4px 4px !important; 
}

.css-1rhbuit-multiValue {
    background-color: white !important;
    border: 1px solid lightgray !important;
    border-radius: 4px !important;
    color: black !important;
    max-width: 175px;
}

.react-date-picker__wrapper {
    border: 1px solid lightgray !important;
    border-radius: 4px;
    height: 100%;

    svg {
        stroke: lightgray;
    }
}

.react-calendar__navigation {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
}

.react-calendar__navigation__label {
    font-size: 16px;
}

.react-calendar__navigation__arrow {
    align-items: center;
    color: darkgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 30px;
}

.leaflet-control { 
    display: none;
}

.leaflet-popup, .leaflet-popup-content {
    border: none;
    margin: 0px;
    width: max-content;
}

.leaflet-popup-content-wrapper {
    background-color: transparent;
    box-shadow: none;
    max-width: none;
    overflow: hidden;
    padding: 0px;
}

.leaflet-popup-tip-container, .leaflet-popup-close-button {
    display: none;
}

.leaflet-draw-tooltip {
    background-color: white;
    color: black;
    font-size: 16px;
}

.leaflet-draw-tooltip-subtext {
	color: $primary;
    font-weight: bold;
}

.polyline-measure-tooltip {
    background-color: white;
}

.polyline-measure-tooltip-end {
    background-color: white;
}

.polyline-measure-tooltip-total {
    color: #006;
}

.polyline-measure-tooltip-difference {
    color: #060;
}

// .polyline-measure-tooltip {
//     background-color: red !important;
//     z-index: 2000 !important;
//     height: fit-content !important;
//     width: fit-content !important;
// }

